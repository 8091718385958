import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SocialShift from "../../components/socialShift"


const SocialShifts = ({ data, location }) => {
  const issues = data.allSanitySocialShiftsPage.edges[0].node.issue.reverse()

  return (
    <Layout 
      scheme="dark" 
      location={location}
      pageCategory="agency"
    >
    <Seo title="MullenLowe U.S. — Social Shifts" />
    <div className="container header-spacer">
      {/* <h1 className="social-shift__header">Social Shifts</h1> */}
      <div className="social-shift__issues">
        {issues.map(issue => (
          <SocialShift
            key={issue.title} 
            title={issue.title}
            copy={issue._rawCopy}
            image={issue.image}
            pdfLocation={issue.pdf.asset.url}
          />
        ))}
      </div>
    </div>
  </Layout>
  )
}

export const query = graphql`
  {
    allSanitySocialShiftsPage {
      edges {
        node {
          issue {
            _rawCopy
            title
            image {
              ...ImageWithPreview
            }
            pdf {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default SocialShifts